* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  margin: 0rem;
  display: flex;
  justify-content: space-between;
  height: 100vh;
}

.new-task {
  border-right: 1px solid;
  flex-basis: 40%;
}

.to-do-list {
  flex-basis: 60%;
  position: relative;
  overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
  main {
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }
  .new-task {
    position: absolute;
    flex-basis: auto;
    width: 100%;
    z-index: 1000;
    background-color: white;
    border: 0;
    border-top: 2px solid;
    border-left: 2px solid;
    border-right: 2px solid;
    border-radius: 20px;
  }
  .to-do-list {
    flex-basis: auto;
    overflow: auto;
  }
}
