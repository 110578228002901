.to-do-list h3.title {
  text-align: center;
  margin: 1.5rem;
}

.to-do-list .name-search input {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 30px;
  padding: 15px;
  border-radius: 3px;
  border: 2px solid rgb(201, 196, 196);
}

.to-do-list .main {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 7rem;
}

.to-do-list .name-search {
  width: 100%;
  padding: 30px 0px;
}

.to-do-list .item {
  border: 1px solid;
  margin-bottom: 20px;
}

.to-do-list .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.to-do-list .action--1,
.action--2 {
  border: none;
  padding: 7px 25px;
  border-radius: 6px;
  color: white;
}

.to-do-list .action--1:hover,
.action--2:hover {
  filter: brightness(0.9);
}

.to-do-list .action--1 {
  background-color: #40cfe6;
}

.to-do-list .action--2 {
  background-color: #e75160;
}

.to-do-list label {
  margin-left: 30px;
}

.to-do-list .head--1 {
  position: relative;
  width: 55%;
}

.to-do-list .head--1 label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: inherit;
}

.to-do-list .checkbox {
  position: absolute;
  top: 6px;
  transform: scale(1.5);
}

.to-do-list .detail {
  height: auto;
  padding: 0px 25px 25px 25px;
  border-top: 1px solid;
}

.to-do-list .main-new-task {
  width: 98%;
  margin: 0 auto;
}

.to-do-list .name-task {
  width: 100%;
  padding: 30px 0px;
}

.to-do-list .name-task input {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 30px;
  padding: 15px;
  border-radius: 3px;
  border: 2px solid rgb(201, 196, 196);
}

.to-do-list .description textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  width: 100%;
  margin-top: 10px;
  border: 2px solid black;
  padding: 10px;
}

.to-do-list .select {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  gap: 0;
}

.to-do-list .select .due-date {
  flex-basis: 47%;
}

.to-do-list .select .priority {
  flex-basis: 47%;
}

.to-do-list .select .due-date input#date,
select {
  width: 100%;
  height: 30px;
  padding: 5px;
  margin-top: 5px;
  border: 2px solid black;
}

.to-do-list #submit {
  width: 100%;
  margin-top: 3rem;
  height: 35px;
  font-size: 1rem;
  background-color: #4caf50;
  border-radius: 5px;
  border: 0;
  color: white;
  font-weight: bold;
}

.to-do-list .bulk {
  position: fixed;
  padding: 30px;
  background-color: rgb(207, 203, 203);
  bottom: 0;
  width: 60%;
  border-top: 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.to-do-list .btnOpen {
  display: none;
  transform: scale(1.7);
  line-height: 1;
}

.to-do-list .head .btn-group {
  display: flex;
  gap: 5px;
}

.to-do-list .bulk .btn-bulk {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 5px;
}

@media only screen and (max-width: 480px) {
  .to-do-list .head .btn-group {
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .to-do-list .btnOpen {
    display: block;
    float: right;
  }
  .to-do-list .main {
    width: 95%;
  }

  .to-do-list .bulk {
    width: 100%;
  }

  .to-do-list .detail {
    padding: 0px 10px 10px 10px;
  }

  .to-do-list .head {
    padding: 8px 15px;
  }
}

@media only screen and (max-width: 992px) {
  .to-do-list .main {
    width: 92%;
  }
  .to-do-list .detail {
    padding: 0px 15px 15px 15px;
  }

  .to-do-list .head--1 {
    width: 50%;
  }
}
